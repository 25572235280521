import { NextSeo } from "next-seo";

import { SiteHeader } from "../components/SiteHeader";
import book from "../images/book.png";
import books1 from "../images/books1.png";

import type { AppProps } from "next/app";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <NextSeo
        title="Książka TypeScript na poważnie - kompletny przewodnik po TypeScripcie!"
        description="TypeScript na poważnie Michała Miszczyszyna to kurs oraz zaawansowane wskazówki i wzorce. Znajdziesz w niej wszystko, co potrzebne, aby poznać TS na wylot!"
        openGraph={{
          title: "Książka „TypeScript na poważnie”",
          description:
            "To kompendium wiedzy na temat TypeScripta! Znajdziesz w niej wszystko, co potrzebne, aby nauczyć się programować w TypeScripcie od podstaw, aż po zaawansowane niuanse, dobre praktyki i wzorce.",
          locale: "pl_PL",
          type: "book",
          book: {
            releaseDate: "2020-11-30T11:08:13Z",
            isbn: "978-8-39-5736339",
            authors: ["https://typeofweb.com/"],
            tags: ["typescript", "javascript"],
          },
          images: [
            {
              url: books1.src,
              width: books1.width,
              height: books1.height,
            },
            {
              url: book.src,
              width: book.width,
              height: book.height,
            },
          ],
        }}
        twitter={{
          handle: "@mmiszy",
          site: "@typeofweb",
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: "1709793622637583",
        }}
        additionalLinkTags={[
          {
            rel: "manifest",
            href: "/site.webmanifest",
          },
          { rel: "preconnect", href: "https://fonts.googleapis.com" },
          {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossOrigin: "true",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
          },
          {
            rel: "mask-icon",
            href: "/safari-pinned-tab.svg",
            color: "#3178c6",
          },
        ]}
      />
      <SiteHeader />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
