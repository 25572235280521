import Link from "next/link";

import { host } from "../constants";

export function SiteHeader() {
  return (
    <header className="-mb-10 h-10 fixed z-20 bg-yellow-300 w-full rounded-b-[2rem]">
      <h1 className="text-3xl font-bold underline sr-only">
        TypeScript na poważnie
      </h1>
      <div className="container h-full flex items-center gap-x-3 xl:gap-x-6 justify-between">
        <nav>
          <ul className="flex flex-row justify-evenly gap-x-3 xl:gap-x-6 uppercase font-bold whitespace-nowrap">
            <li className="hidden sm:block">
              <Link href={`${host}#o-ksiazce`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  O książce
                </a>
              </Link>
            </li>
            <li>
              <Link href={`${host}#opinie`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  Opinie
                </a>
              </Link>
            </li>
            <li>
              <Link href={`${host}#o-autorze`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  O autorze
                </a>
              </Link>
            </li>
            <li className="hidden lg:block">
              <Link href={`${host}#kompletny-przewodnik`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  Kompletny przewodnik
                </a>
              </Link>
            </li>
            <li className="hidden sm:block">
              <Link href={`${host}#dla-kogo`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  Dla kogo
                </a>
              </Link>
            </li>
            <li className="hidden sm:block">
              <Link href={`${host}#spis-tresci`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  Spis treści
                </a>
              </Link>
            </li>
            <li>
              <Link href={`${host}#kup-teraz`}>
                <a className="hover:underline funny-focus rounded-sm ring-offset-yellow-300">
                  Kup teraz
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        <ul className="hidden md:flex flex-row gap-x-3 -mt-1">
          <li>
            <a
              href="https://facebook.com/typeofweb"
              className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
            >
              <svg aria-label="facebook" className="icon icon-facebook2">
                <use xlinkHref="#icon-facebook2"></use>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://youtube.com/c/typeofweb"
              className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
            >
              <svg aria-label="youtube" className="icon icon-youtube">
                <use xlinkHref="#icon-youtube"></use>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://instagram.com/michal_typeofweb"
              className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
            >
              <svg aria-label="instagram" className="icon icon-instagram">
                <use xlinkHref="#icon-instagram"></use>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/typeofweb"
              className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
            >
              <svg aria-label="twitter" className="icon icon-twitter">
                <use xlinkHref="#icon-twitter"></use>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://linkedin.com/in/mmiszczyszyn"
              className="text-lg funny-focus rounded-sm ring-offset-yellow-300"
            >
              <svg aria-label="linkedin" className="icon icon-linkedin">
                <use xlinkHref="#icon-linkedin"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}
